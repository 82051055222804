export function XIconRedFill() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <rect
        width="80"
        height="80"
        rx="40"
        fill="url(#paint0_linear_186_4166)"
      />
      <path
        d="M27 27L53 53"
        stroke="white"
        stroke-width="3.75"
        stroke-linecap="round"
      />
      <path
        d="M53 27L27 53"
        stroke="white"
        stroke-width="3.75"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_186_4166"
          x1="40"
          y1="0"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BB2D13" />
          <stop offset="1" stop-color="#991800" />
        </linearGradient>
      </defs>
    </svg>
  );
}
